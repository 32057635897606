<template>
  <div class="vx-row">
    <div class="vx-col w-full mb-base" v-if="is_filter">
      <vx-card title="Filter">
        <div class="vx-row mb-3">
          <div class="vx-col lg:w-1/3 md:w-1/2 sm:w-3/4 w-full">
                <small class="ml-2">Period</small>
                <div class='flex align-items-center'>
                    <flat-pickr :config="flatPickrConfig" class="w-full" v-model="filterData.date_start" @input='getData()'></flat-pickr>
                    <small class='ml-5 mr-5'>Until</small>
                    <flat-pickr :config="flatPickrConfig" class="w-full" v-model="filterData.date_end"  @input='getData()'></flat-pickr>
                </div>
          </div>
        </div>
        <div class="vx-row mb-3">
          <div class="vx-col lg:w-1/3 md:w-1/2 sm:w-3/4 w-full">
                <browse-contact :isdriver="true" v-validate="'required'" name="driver_id" data-vv-as="Default driver" label="Driver" v-model="filterData.driver_id" v-on:onChangeContact="changeDriver"></browse-contact>
          </div>
        </div>
        <div class="vx-row mb-3">
          <div class="vx-col lg:w-1/3 md:w-1/2 sm:w-3/4 w-full">
                <browse-vehicle class="w-full"  name="vehicle_id" label="Vehicle" v-model="filterData.vehicle_id" @on-change="changeVehicle"></browse-vehicle>
          </div>
        </div>
        <div class="vx-col w-full flex mt-3">
          <vs-button @click="exportExcel()" color="success">Export Excel</vs-button>&nbsp;
          <vs-button color="warning" @click="resetFilter()">Reset</vs-button>
        </div>
      </vx-card>
    </div>
    <div class="vx-col w-full mb-base">
      <vx-card title="Drivers">
        <vs-table :sst="true" search @search="handleSearch" :data="data.data" @sort="handleSort" class="mb-3" >
          <template slot="header">
            <vs-button color="primary" @click="is_filter = !is_filter">Filter</vs-button>
          </template>
          <template slot="thead">
            <vs-th sort-key="contact_name">Driver</vs-th>
            <vs-th sort-key="delivery_order_code">Shipment No.</vs-th>
            <vs-th sort-key="type">Source</vs-th>
            <vs-th sort-key="job_assign_time">Date</vs-th>
            <vs-th sort-key="vehicle">Vehicle</vs-th>
            <vs-th sort-key="status_name">Status</vs-th>
          </template>
          <template slot-scope="{data}">
            <vs-tr :key="indextr" v-for="(tr, indextr) in data">
              <vs-td :data="tr.contact_name">{{tr.contact_name}}</vs-td>
              <vs-td label="Shipment No." :data="tr.delivery_order_code">{{tr.delivery_order_code}}</vs-td>
              <vs-td label="Source" :data="tr.type">{{tr.type}}</vs-td>
              <vs-td label="Date" :data="tr.job_assign_time">{{tr.job_assign_time}}</vs-td>
              <vs-td label="Vehicle" :data="tr.vehicle">{{tr.vehicle}}</vs-td>
              <vs-td label="Status" :data="tr.status_name">{{tr.status_name}}</vs-td>
            </vs-tr>
          </template>
        </vs-table>
        <vs-pagination :total="data.last_page" v-model="currentPage" class="mt-5"></vs-pagination>
      </vx-card>
    </div>
  </div>
</template>

<script>
import {mapActions, mapState} from 'vuex'
import axios from '@/axios'
import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';
import BrowseContact from '@/views/browse_input/Contact.vue'
import BrowseVehicle from '@browse/Vehicle.vue'

export default {
  components:{
    flatPickr,
    BrowseContact,
    BrowseVehicle
  },
  data(){
    return {
      filterData : {},
      is_filter : false,
      idDelete: null,
      currentPage: 1,
      search:null,
      sortKey:null,
      sortVal:null,
      flatPickrConfig: {
            altFormat: 'j M Y',
            altInput: true,
            dateFormat: 'Y-m-d',
      }
    }
  },
  watch:{
    search(){
      this.currentPage=1
      this.getData()
    },
    currentPage(){
      this.getData()
    }
  },
  computed:{
    ...mapState({
      data: state => state.drivers.rows
    })
  },
  methods:{
    ...mapActions({
      dispatchIndex: 'drivers/index'
    }),
    async getData(){
      let payload = {
        page: this.currentPage,
        search: this.search,
        sortKey: this.sortKey,
        sortVal: this.sortVal
      }
      payload = Object.assign(payload, this.filterData)
      await this.dispatchIndex(payload)
    },
    handleSearch(val){
      this.search = val
    },
    handleSort(key,val){
      this.sortKey = key
      this.sortVal = val
      this.getData()
    },
    async changeDriver(data){
      this.filterData.driver_id = data.id
      await this.getData();
    },
    async changeVehicle(data){
      this.filterData.vehicle_id = data.id
      await this.getData();
    },
    async exportExcel() {
      await axios.post(`operational/exports/drivers`, this.filterData, {
            responseType: 'blob'
        }).then((response) => {
            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(
                new Blob([response.data])
            );
            link.setAttribute('download', 'Driver.xlsx');
            document.body.appendChild(link);
            link.click();
      });
    },
    async resetFilter() {
      this.filterData = {}
      await this.getData()
    }
  },
  async mounted(){
    await this.getData()
  }
}
</script>

<style>

</style>